
::v-deep .el-dialog {
    width: 1020px;
}

.sort-box {
    display: flex;
    justify-content: space-between;

    .row {
        display: flex;
        line-height: 3;

        ::v-deep .el-input__inner {
            width: 220px;
        }
    }
}

.main-box {
    height: 680px;
    margin-top: 28px;
    border: 1px solid #C4BCB7;

    .link-box {
        padding-top: 30px;
        padding-right: 80px;
        height: 460px;
    }

    .application-content {
        padding: 20px 0;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .app-content {
            height: 100%;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }
        }

        .select-wrapper {
            padding: 0 20px;

            .select-btn {
                text-align: right;

                .checkbox-all {
                    margin-right: 20px;
                }
            }
        }

        .app-list-ul {
            padding: 0 20px;
            display: flex;
            flex-wrap: wrap;

            li.app-list-li {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin: 10px 19px 20px;
                cursor: pointer;
                border-radius: 10px;

                .app-cover {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 70px;
                    height: 70px;

                    .checkbox {
                        position: absolute;
                        top: 0;
                        right: 0;

                        .checkbox-group {
                            .checkbox-label {
                                ::v-deep .el-checkbox__label {
                                    display: none;
                                }
                            }
                        }
                    }
                }

                img.app-icon {
                    max-width: 100%;
                    max-height: 100%;
                    border-radius: 5px;
                }

                span.app-name {
                    color: #333;
                    font-size: 12px;
                    margin-top: 10px;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 90.25px;
                    text-align: center;
                }
            }
        }
    }

    .show-content {
        width: 100%;
        height: 100%;

        .video-box {
            width: 100%;
            height: 100%;

            video {
                width: 100%;
                height: 100%;
            }
        }

        .other-box {
            width: 100%;
            height: 100%;
        }
    }
}

