
ul,li{
    margin: 0;
    padding: 0;
    list-style: none;
}
.training-submission{
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    .detail-title{
        cursor: pointer;
        &.detail-title:hover{
            color: #409eff;
        }
    }
    .detail-text{
        margin-bottom: 10px;
       ::v-deep img {
            max-width: 100%;
            max-height: 100%;
        }
    }

}
.pages-center {
    margin-bottom: 20px;
}
.teacher-comments {
    display: flex;
    flex-direction: column;
    height: 200px;
    padding: 0 20px;
    margin-top: 10px;
    .comments {
        padding: 20px;
    }
    .textarea {
        padding-top: 20px;
    }
}
.pw-input {
    width: 120px;
    margin-right: 5px;
}
::v-deep.el-dialog__body{
    padding: 30px 0;
}
.comment-content {
    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .comment-txt {
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }
}
.comment-btns {
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
